var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from "graphql-tag";
import SetLanguageDialog from "../ui/SetLanguageDialog.vue";
import AsyncStatus from "../../utils/async";
import PasswordForm from "../ui/PasswordForm.vue";
import updatePassword from "../../api/mutations/updatePassword";
import mixins from "vue-typed-mixins";
import NotificationsMixin from "../ui/NotificationsMixin";
export default mixins(NotificationsMixin).extend({
    components: {
        SetLanguageDialog: SetLanguageDialog,
        PasswordForm: PasswordForm,
    },
    apollo: {
        agent: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        query AgentWelcomeQuery($id: ID!) {\n          agent(id: $id) {\n            id\n            hasCompletedSignup\n            userId\n          }\n        }\n      "], ["\n        query AgentWelcomeQuery($id: ID!) {\n          agent(id: $id) {\n            id\n            hasCompletedSignup\n            userId\n          }\n        }\n      "]))),
            variables: function () {
                return {
                    id: this.$route.params.id,
                };
            },
        },
    },
    data: function () {
        return {
            status: new AsyncStatus(),
        };
    },
    methods: {
        onProcessPassword: function (password) {
            var _this = this;
            if (!this.agent) {
                throw new Error("Invalid state");
            }
            return this.$apollo
                .mutate(updatePassword(this.agent.userId, password))
                .then(function () {
                _this.displaySuccessMessage(_this.$t("Your password has been set"));
                _this.$router.push("/sign-in");
            });
        },
    },
    metaInfo: function () {
        return {
            title: this.$t("Welcome").toString(),
        };
    },
});
var templateObject_1;
